import React from 'react';

// eslint-disable-next-line import/no-named-as-default
import FormControl from '@nib-components/form-control';
import Checkbox from '@nib-components/checkbox';

import { BaseFormikProps } from '../../../pages/ngatiwhatuaorakei/enrol';

interface CheckboxFormControlProps extends Omit<BaseFormikProps, 'values' | 'touched' | 'errors'> {
  value: boolean;
  fieldName: string;
  label: string | JSX.Element;
  id: string;
  width?: string | number;
  isEmptyAndOptional?: boolean;
  valid?: boolean;
  error?: string;
  isTouched?: boolean;
}

const CheckboxFormControl = (props: CheckboxFormControlProps) => {
  const {
    value,
    id,
    label,
    fieldName,
    setFieldTouched,
    handleBlur,
    handleChange,
    isEmptyAndOptional,
    valid,
    error,
    isTouched,
    isSubmitting,
  } = props;

  return (
    <FormControl
      onFocus={() => setFieldTouched(fieldName, false)}
      onBlur={handleBlur}
      id={id}
      name={fieldName}
      validated={isTouched}
      valid={valid}
      width="100%"
      isEmptyAndOptional={isEmptyAndOptional}
      error={error}
      disabled={isSubmitting}
      label=""
    >
      <Checkbox
        onChange={handleChange(fieldName)}
        // @ts-ignore
        value={value}
        // @ts-ignore
        label={label}
        checked={!!value}
      />
    </FormControl>
  );
};

export default CheckboxFormControl;
