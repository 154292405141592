import React, { useState } from 'react';

import Container from '@nib-components/container';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import { Stack, Columns, Column } from '@nib/layout';

import useNzPostAddressChecker from '../../hooks/useNzPostAddressChecker';
import { BaseFormikProps, sharedColumnSpace } from '../../pages/ngatiwhatuaorakei/enrol';
import TextboxFormControl from '../FormControl/TextboxFormControl';
import { validateAddress } from '../../utils/form-validation';

require('../../scripts/addressChecker.js');

interface AddressDetailsProps extends BaseFormikProps {}

declare global {
  interface Window {
    NZPost: any;
  }
}

interface NzPostResult {
  AddressLine1: string;
  RuralDelivery: string;
  MailTown: string;
  FullAddress: string;
  AddressLine2: string;
  Suburb: string;
  CityTown: string;
  Postcode: string;
  BoxBagType: string;
}

interface UseNzPostProps extends BaseFormikProps {}

const useNzPost = (props: UseNzPostProps) => {
  const { setFieldValue, setFieldTouched } = props;

  // Typecheck is required for the gatsby build
  const element =
    typeof document !== 'undefined' && document.getElementsByClassName('autoFillAddress')[0];

  const onSuccess = (result: NzPostResult) => {
    setFieldValue('autoFillAddress', result.FullAddress);
    setFieldValue('numberAndStreet', result.AddressLine1);
    setFieldValue('postCode', result.Postcode);
    setFieldValue('country', 'New Zealand');

    if (result.RuralDelivery) {
      setFieldValue('suburb', result.RuralDelivery);
      setFieldValue('city', result.MailTown);
    } else {
      if (result.BoxBagType) {
        setFieldValue('suburb', result.AddressLine2);
      } else {
        setFieldValue('suburb', result.Suburb);
      }
      setFieldValue('city', result.CityTown);
    }

    setFieldTouched('numberAndStreet', true);
    setFieldTouched('postCode', true);
    setFieldTouched('country', true);
    setFieldTouched('suburb', true);
    setFieldTouched('city', true);
  };

  useNzPostAddressChecker({ onSuccess, element });
};

const AddressFormSection = (props: AddressDetailsProps): JSX.Element => {
  // TODO: Set isManual to false to show automatic finder on load
  const [isManual, setIsManual] = useState(false);
  const { touched, values, setFieldTouched, setFieldValue, errors } = props;

  useNzPost({ ...props });

  const { numberAndStreet, suburb, city, country, postCode, autoFillAddress } = values;

  const toggleAddressMode = () => {
    if (isManual) {
      setFieldValue('autoFillAddress', '');
      setFieldValue('numberAndStreet', '');
      setFieldValue('postCode', '');
      setFieldValue('country', '');
      setFieldValue('suburb', '');
      setFieldValue('city', '');
      setFieldTouched('numberAndStreet', false);
      setFieldTouched('postCode', false);
      setFieldTouched('country', false);
      setFieldTouched('suburb', false);
      setFieldTouched('city', false);
      setFieldTouched('autoFillAddress', false);
    }
    setIsManual(!isManual);
  };

  return (
    <Container>
      <Stack space={4}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
          Address
        </Heading>
        {isManual ? (
          <Stack space={4}>
            <Columns space={sharedColumnSpace} collapseBelow="md">
              <Column width="2/3">
                <TextboxFormControl
                  id="address"
                  value={numberAndStreet}
                  isTouched={touched.numberAndStreet}
                  fieldName="numberAndStreet"
                  autoComplete="street-address"
                  label="Address"
                  error={errors.numberAndStreet}
                  valid={!errors.numberAndStreet}
                  {...props}
                />
              </Column>
              <Column width="1/3">
                <TextboxFormControl
                  id="suburb"
                  value={suburb}
                  isTouched={touched.suburb}
                  fieldName="suburb"
                  autoComplete="address-level3"
                  label="Suburb"
                  valid={true}
                  {...props}
                />
              </Column>
            </Columns>
            <Columns space={sharedColumnSpace} collapseBelow="md">
              <Column width="1/3">
                <TextboxFormControl
                  id="city"
                  value={city}
                  isTouched={touched.city}
                  fieldName="city"
                  autoComplete="address-level2"
                  label="City"
                  error={errors.city}
                  valid={!errors.city}
                  {...props}
                />
              </Column>
              <Column width="1/3">
                <TextboxFormControl
                  id="country"
                  value={country}
                  isTouched={touched.country}
                  fieldName="country"
                  autoComplete="country-name"
                  label="Country"
                  error={errors.country}
                  valid={!errors.country}
                  {...props}
                />
              </Column>
              <Column width="1/3">
                <TextboxFormControl
                  id="postcode"
                  value={postCode}
                  isTouched={touched.postCode}
                  fieldName="postCode"
                  autoComplete="postal-code"
                  label="Postcode"
                  valid={true}
                  {...props}
                />
              </Column>
            </Columns>
          </Stack>
        ) : (
          <Columns>
            <Column width="1">
              <TextboxFormControl
                id="auto-fill-address"
                textboxClassName="autoFillAddress"
                value={autoFillAddress}
                isTouched={touched.autoFillAddress}
                fieldName="autoFillAddress"
                autoComplete="street-address"
                label="Address"
                error={errors.numberAndStreet}
                valid={!errors.numberAndStreet}
                {...props}
              />
            </Column>
          </Columns>
        )}
        <Link underline={false} component="div" onClick={() => toggleAddressMode()}>
          {isManual ? 'Return to address finder' : 'Or enter my address manually'}
        </Link>
      </Stack>
    </Container>
  );
};

export const validateAddressDetails = (errors, values) => {
  validateAddress(errors, values, 'numberAndStreet', 'address');
  validateAddress(errors, values, 'city', 'city');
  validateAddress(errors, values, 'country', 'country');
};

export default AddressFormSection;
