import React from 'react';

import Container from '@nib-components/container';
import Divider from '@nib-components/divider';
import { Stack } from '@nib/layout';
import { PrimaryButton } from '@nib-components/button';
import Link from '@nib-components/link';
import Alert from '@nib-components/alert';
import { CommErrorMessage } from '../shared/ContactUsApiCommon';

import { BaseFormikProps } from '../../pages/ngatiwhatuaorakei/enrol';
import CheckboxFormControl from './controls/CheckboxFormControl';
import { scrollToError } from '../../utils/form-validation';

interface AgreeAndSubmitFormSectionProps extends BaseFormikProps {}

const AgreeAndSubmitFormSection = (props: AgreeAndSubmitFormSectionProps) => {
  const { touched, values, isSubmitting, status, errors } = props;
  const { conditionsAccepted } = values;

  return (
    <Container>
      <Stack space={4}>
        <Divider />
        <CheckboxFormControl
          id={`conditions-accepted`}
          value={conditionsAccepted}
          fieldName="conditionsAccepted"
          label={
            <span>
              Yes I've read and agree to the{' '}
              <Link href="http://nib.co.nz/privacy-policy" target="_blank">
                privacy policy
              </Link>{' '}
              for myself and everyone on this enrolment form and I have authority to provide
              personal information about everyone on this form
            </span>
          }
          width="100%"
          isTouched={touched.conditionsAccepted}
          valid={!errors.conditionsAccepted}
          error={errors.conditionsAccepted}
          {...props}
        />
        {status && !status.submitSucceeded && status.errorMessage && (
          <Alert type="error" title="Oops, something went wrong" fullWidth={false}>
            <CommErrorMessage />
          </Alert>
        )}
        <PrimaryButton type="submit" disabled={isSubmitting} onClick={() => scrollToError()}>
          Submit form
        </PrimaryButton>
      </Stack>
    </Container>
  );
};

export const validateConditionsAccepted = (errors, values) => {
  if (!values.conditionsAccepted) {
    errors.conditionsAccepted = 'You must agree to the privacy policy';
  }
};

export default AgreeAndSubmitFormSection;
