import React from 'react';

import Container from '@nib-components/container';
import Heading from '@nib-components/heading';
import { Stack, Columns, Column } from '@nib/layout';

import { BaseFormikProps, sharedColumnSpace } from '../../pages/ngatiwhatuaorakei/enrol';
import { isEmpty, validatePhoneNumbers, validateEmail } from '../../utils/form-validation';
import TextboxFormControl from '../FormControl/TextboxFormControl';

interface ContactDetailsProps extends BaseFormikProps {}

const ContactDetailsFormSection = (props: ContactDetailsProps): JSX.Element => {
  const { touched, values, errors } = props;
  const { homePhone, mobilePhone, workPhone, email } = values;

  return (
    <Container>
      <Stack space={4}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
          Contact details
        </Heading>
        <Columns space={sharedColumnSpace} collapseBelow="md">
          <Column width="1/3">
            <TextboxFormControl
              id="home-phone"
              value={homePhone}
              isTouched={touched.homePhone}
              fieldName="homePhone"
              autoComplete="tel"
              label="Home phone"
              isEmptyAndOptional={true}
              error={errors.homePhone}
              valid={!errors.homePhone}
              {...props}
            />
          </Column>
          <Column width="1/3">
            <TextboxFormControl
              id="mobile-phone"
              value={mobilePhone}
              isTouched={touched.mobilePhone}
              fieldName="mobilePhone"
              autoComplete="tel"
              label="Mobile phone"
              isEmptyAndOptional={true}
              error={errors.mobilePhone}
              valid={!errors.mobilePhone}
              {...props}
            />
          </Column>
          <Column width="1/3">
            <TextboxFormControl
              id="work-phone"
              value={workPhone}
              isTouched={touched.workPhone}
              fieldName="workPhone"
              autoComplete="tel"
              label="Work phone"
              isEmptyAndOptional={true}
              error={errors.workPhone}
              valid={!errors.workPhone}
              {...props}
            />
          </Column>
        </Columns>
        <Columns>
          <Column width="1">
            <TextboxFormControl
              id="email"
              value={email}
              isTouched={touched.email}
              fieldName="email"
              autoComplete="email"
              label="Email"
              error={errors.email}
              valid={!errors.email}
              {...props}
            />
          </Column>
        </Columns>
      </Stack>
    </Container>
  );
};

export const validateContactDetails = (errors, values) => {
  validatePhoneNumbers(errors, values, 'homePhone');
  validatePhoneNumbers(errors, values, 'mobilePhone');
  validatePhoneNumbers(errors, values, 'workPhone');

  if (isEmpty(values.homePhone) && isEmpty(values.mobilePhone) && isEmpty(values.workPhone)) {
    errors.homePhone = 'Please enter at least one phone number';
  }

  validateEmail(errors, values, 'email');
};

export default ContactDetailsFormSection;
