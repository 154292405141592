import React from 'react';

// eslint-disable-next-line import/no-named-as-default
import FormControl from '@nib-components/form-control';
import Select from '@nib-components/select';

import { BaseFormikProps } from '../../../pages/ngatiwhatuaorakei/enrol';

export interface SelectShape {
  [key: string]: string;
}

interface SelectFormControlProps extends Omit<BaseFormikProps, 'values' | 'touched' | 'errors'> {
  value: string;
  isTouched?: boolean;
  fieldName: string;
  label: string;
  id: string;
  error?: string;
  valid?: boolean;
  width?: string | number;
  options: SelectShape;
  placeholder?: string;
}

const SelectFormControl = (props: SelectFormControlProps) => {
  const {
    value,
    id,
    isTouched,
    label,
    fieldName,
    setFieldTouched,
    handleBlur,
    handleChange,
    options,
    placeholder,
    isSubmitting,
    error,
    valid,
  } = props;

  return (
    <FormControl
      onFocus={() => setFieldTouched(fieldName, false)}
      onBlur={handleBlur}
      onChange={handleChange}
      value={value}
      validated={isTouched}
      valid={valid}
      error={error}
      id={id}
      name={fieldName}
      label={label}
      isEmptyAndOptional={true}
      disabled={isSubmitting}
    >
      <Select options={options} placeholder={placeholder} />
    </FormControl>
  );
};

export default SelectFormControl;
