import React from 'react';

// eslint-disable-next-line import/no-named-as-default
import FormControl from '@nib-components/form-control';
import DateTextbox from '@nib-components/date-textbox';

import { BaseFormikProps } from '../../../pages/ngatiwhatuaorakei/enrol';

interface DateTextboxFormControlProps
  extends Omit<BaseFormikProps, 'values' | 'touched' | 'errors'> {
  value: string;
  isTouched?: boolean;
  fieldName: string;
  label: string;
  id: string;
  error?: string;
  valid?: boolean;
  width?: string | number;
  isEmptyAndOptional?: boolean;
}

const DateTextboxFormControl = (props: DateTextboxFormControlProps) => {
  const {
    value,
    id,
    label,
    fieldName,
    setFieldTouched,
    handleBlur,
    handleChange,
    isTouched,
    isEmptyAndOptional,
    isSubmitting,
    error,
    valid,
  } = props;

  return (
    <FormControl
      onFocus={() => setFieldTouched(fieldName, false)}
      onBlur={handleBlur}
      validated={isTouched}
      id={id}
      name={fieldName}
      label={label}
      error={error}
      valid={valid}
      isEmptyAndOptional={isEmptyAndOptional}
      width="100%"
      disabled={isSubmitting}
    >
      <DateTextbox autoComplete="bday" onChange={handleChange(fieldName)} value={value} />
    </FormControl>
  );
};

export default DateTextboxFormControl;
