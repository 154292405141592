import { useEffect } from 'react';
import { NzPostResult } from '../constructs/models';
import useAccessToken from './useAccessToken';

interface useNzPostAddressCheckerProps {
  element: Element | false;
  onSuccess: (result: NzPostResult) => void;
}

const useNzPostAddressChecker = (props: useNzPostAddressCheckerProps) => {
  const { onSuccess, element } = props;
  const accessToken = useAccessToken();

  const lookupOptions = {
    success: onSuccess,
    max_results: 5,
    theme: {
      textColor: '#008a20',
    },
  };

  useEffect(() => {
    if (element) {
      new window.NZPost.Addressing.Checker(element, accessToken, lookupOptions);
    }
  });
};

export default useNzPostAddressChecker;
