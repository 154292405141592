import React from 'react';
import { pullAt } from 'lodash';
import Container from '@nib-components/container';
import Divider from '@nib-components/divider';
import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import { SecondaryButton } from '@nib-components/button';
import { Box, Stack, Columns, Column, Inline } from '@nib/layout';
import { AddSystemIcon } from '@nib/icons';

import {
  addEmptyChildDetails,
  BaseFormikProps,
  sharedColumnSpace,
} from '../../pages/ngatiwhatuaorakei/enrol';
import TextboxFormControl from '../FormControl/TextboxFormControl';
import DateTextboxFormControl from './controls/DateTextboxFormControl';
import RadioButtonFormControl from './controls/RadioButtonFormControl';
import CheckboxFormControl from './controls/CheckboxFormControl';
import { isEmpty, isInvalidDate, isInvalidGender } from '../../utils/form-validation';

const isValidField = (errors: any, index: number, fieldName: string): boolean => {
  if (errors?.childrenDetails?.[index]?.[fieldName]) {
    return false;
  }
  return true;
};

const getErrorMessage = (errors: any, index: number, fieldName: string): string | undefined => {
  if (errors?.childrenDetails?.[index]?.[fieldName]) {
    return errors.childrenDetails[index][fieldName];
  }
  return undefined;
};

interface ChildrenDetailsFormProps extends BaseFormikProps {}

const ChildrenDetailsFormSection = (props: ChildrenDetailsFormProps): JSX.Element => {
  const { touched, values, setFieldValue, errors } = props;
  const { childrenDetails } = values;

  return (
    <Container>
      <Stack space={4}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
          Children details
        </Heading>
        <Copy measure={false}>
          Your insurance policy benefits will apply to all of your tamariki who are under 18 and are
          registered members of Ngāti Whātua Ōrākei.
        </Copy>
        {childrenDetails.map((childDetails, index) => {
          const { fullName, dateOfBirth, gender, isOfAge } = childDetails;
          return (
            <Stack space={4} key={index}>
              <Columns space={sharedColumnSpace} collapseBelow="md">
                <Column width={{ lg: '2/5', xxl: '1/2' }}>
                  <TextboxFormControl
                    id={`child-full-name-${index}`}
                    value={fullName}
                    isTouched={touched?.childrenDetails?.[index]?.fullName}
                    fieldName={`childrenDetails[${index}].fullName`}
                    label="Full name"
                    isEmptyAndOptional={true}
                    valid={isValidField(errors, index, 'fullName')}
                    error={getErrorMessage(errors, index, 'fullName')}
                    {...props}
                  />
                </Column>
                <Column width="1/4">
                  <DateTextboxFormControl
                    id={`child-date-of-birth-${index}`}
                    value={dateOfBirth}
                    isTouched={touched?.childrenDetails?.[index]?.dateOfBirth}
                    fieldName={`childrenDetails[${index}].dateOfBirth`}
                    label="Date of Birth"
                    isEmptyAndOptional={true}
                    valid={isValidField(errors, index, 'dateOfBirth')}
                    error={getErrorMessage(errors, index, 'dateOfBirth')}
                    {...props}
                  />
                </Column>
                <Column width={{ lg: '2/5', xxl: '1/4' }}>
                  <RadioButtonFormControl
                    id={`child-gender-${index}`}
                    value={gender}
                    isTouched={touched?.childrenDetails?.[index]?.gender}
                    fieldName={`childrenDetails[${index}].gender`}
                    label="Gender"
                    options={{ male: 'Male', female: 'Female' }}
                    showButtonStyle={true}
                    width="100%"
                    valid={isValidField(errors, index, 'gender')}
                    error={getErrorMessage(errors, index, 'gender')}
                    {...props}
                  />
                </Column>
              </Columns>
              <Columns space={sharedColumnSpace} collapseBelow="md">
                <Column>
                  <CheckboxFormControl
                    id={`child-is-of-age-${index}`}
                    value={isOfAge}
                    fieldName={`childrenDetails[${index}].isOfAge`}
                    label="Yes, my child is under 18 and a registered Ngāti Whātua Ōrākei member"
                    width="100%"
                    isEmptyAndOptional={true}
                    valid={true}
                    isTouched={false}
                    {...props}
                  />
                </Column>
              </Columns>
              <Box textAlign="right">
                <SecondaryButton
                  onClick={() => {
                    pullAt(childrenDetails, index);
                    setFieldValue('childrenDetails', childrenDetails);
                  }}
                >
                  Remove
                </SecondaryButton>
              </Box>
              <Divider />
            </Stack>
          );
        })}
        <SecondaryButton
          onClick={() =>
            setFieldValue('childrenDetails', [...childrenDetails, addEmptyChildDetails()])
          }
          disabled={childrenDetails.length >= 20}
        >
          <Inline space={3} verticalAlign="center" align="center">
            <AddSystemIcon />
            <Copy>Add a child</Copy>
          </Inline>
        </SecondaryButton>
      </Stack>
    </Container>
  );
};

export const validateChildren = (errors, children) => {
  const childrenArrayErrors: any[] = [];
  children &&
    children.forEach((child, childIndex) => {
      const childErrors: any = {};

      /*
       * The form on initial render displays a row of children inputs that are optional
       * unless one of the inputs has been correctly filled in.
       */
      const shouldValidateChildren = !(
        isEmpty(child.fullName) &&
        isInvalidDate(child.dateOfBirth) &&
        isInvalidGender(child.gender)
      );

      if (shouldValidateChildren) {
        if (isEmpty(child.fullName)) {
          childErrors.fullName = 'Please provide the full name';
        }
        if (isInvalidDate(child.dateOfBirth)) {
          childErrors.dateOfBirth = 'Please enter date of birth';
        }
        if (isInvalidGender(child.gender)) {
          childErrors.gender = 'Please select a gender';
        }
        if (Object.keys(childErrors).length !== 0) {
          childrenArrayErrors[childIndex] = childErrors;
        }
      }
    });
  if (childrenArrayErrors.length) {
    errors.childrenDetails = childrenArrayErrors;
  }
};

export default ChildrenDetailsFormSection;
