import { template } from 'lodash';

const compiledNwoTemplate = template(`
  <div>
    <div>Kia ora,</div>
    <br />
    <div>
      A Ngāti Whātua Ōrākei individual has completed the online nib registration form.
      Please confirm registration, and if applicable add them to the signup sheet to be sent to nib.
      Please note a policy will only be set up once received on the signup sheet from Ngāti Whātua Ōrākei.
    </div>

    <br />
    <div><b>Enrolment Details</b></div>

    <div>Title, FirstName, MiddleName, LastName, DOB, Gender, PreferredGender, HomePhone, MobilePhone, WorkPhone, EmailAddress, Address, Suburb, City, Country, Postcode, Referrer</div>

    <div><%- enrolment %></b></div>

    <br />
    <div><b>Children</b></div>

    <div>Fullname, DOB, Gender, IsUnder18AndRegisteredNWOMember</div>
    <br />
    <% childrenDetails.forEach(function(childData) { %>
      <div><%- childData %></div>
      <br />
    <% }); %>

    <br />
    <div><b>Other Members</b></div>

    <div>Fullname, DOB, Gender</div>
    <br />
    <% nonMemberDetails.forEach(function(memberData) { %>
      <div><%- memberData %></div>
      <br />
    <% }); %>

    <br />
    <div><b>Additional Details</b></div>
    <div>Reference Id</div>
    <div><%- referenceId %></b></div>
    <br />
    <div>Conditions accepted</div>
    <div><%- conditionsAccepted %></b></div>

    <br />
    <div>Ngā mihi</div>
    <div>The team at nib</div>
  </div>
`);

const fieldsEnrolmentDetails = [
  'title',
  'firstName',
  'middleName',
  'surname',
  'dateOfBirth',
  'gender',
  'preferredGender',
  'homePhone',
  'mobilePhone',
  'workPhone',
  'email',
  'numberAndStreet',
  'suburb',
  'city',
  'country',
  'postCode',
  'referralName',
];

const toCommaSeparatedValues = (data, whitelistFilter) =>
  Object.entries<string>(data || [])
    .reduce((res, [key, value]) => {
      if (whitelistFilter.includes(key)) {
        res.push(value);
      }
      return res;
    }, [] as string[])
    .join(', ');

const toListOfCommaSeparatedValues = (data: object[]) =>
  data.reduce<string[]>((details, data) => {
    let text = Object.values(data || []).join(', ');
    details.push(text);
    return details;
  }, [] as string[]);

export const generateNwoEmailBody = (data) => (referenceId) => {
  const enrolment = toCommaSeparatedValues(data, fieldsEnrolmentDetails);
  const childrenDetails = toListOfCommaSeparatedValues(data.childrenDetails);
  const nonMemberDetails = toListOfCommaSeparatedValues(data.nonMemberDetails);
  const conditionsAccepted = !!data.conditionsAccepted;

  return compiledNwoTemplate({
    referenceId,
    conditionsAccepted,
    enrolment,
    childrenDetails,
    nonMemberDetails,
  });
};
