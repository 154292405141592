import { useEffect, useState } from 'react';
import config from '../utils/env';

interface ResponseJsonShape {
  accessToken: string;
}

function useAccessToken() {
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    const unlisten = async () => {
      const apiRoot = config.apiRoot;
      const result = await fetch(`${apiRoot}/sales/address-search-access`, {
        method: 'get',
        headers: { 'X-NibNZ-Version': '1' },
      });

      const json = (await result.json()) as ResponseJsonShape;

      setAccessToken(json.accessToken);
    };
    unlisten();
  }, [accessToken]);

  return accessToken;
}

export default useAccessToken;
