/**
 * @fileOverview Javascript Library for Address Autosuggesting
 * @author Able Technology
 */
(function (e) {
  var t = e.setTimeout,
    n = e.document,
    r = 0;
  e.jXHR = function () {
    function f() {
      try {
        u.parentNode.removeChild(u);
      } catch (e) {}
    }

    function l() {
      (s = !1), (i = ''), f(), (u = null), p(0);
    }

    function c(e) {
      try {
        a.onerror.call(a, e, i);
      } catch (t) {
        throw new Error(e);
      }
    }

    function h() {
      if ((this.readyState && this.readyState !== 'complete' && this.readyState !== 'loaded') || s)
        return;
      (this.onload = this.onreadystatechange = null),
        (s = !0),
        a.readyState !== 4 && c('Script failed to load [' + i + '].'),
        f();
    }

    function p(e, t) {
      (t = t || []),
        (a.readyState = e),
        typeof a.onreadystatechange == 'function' && a.onreadystatechange.apply(a, t);
    }
    var i,
      s,
      o,
      u,
      a = null;

    var xhr;

    return (
      (a = {
        onerror: null,
        onreadystatechange: null,
        readyState: 0,
        open: function (t, n) {
          xhr = new XMLHttpRequest();
          if ('withCredentials' in xhr) {
            // XHR for Chrome/Firefox/Opera/Safari.
            xhr.open(t, n, true);
          } else if (typeof XDomainRequest != 'undefined') {
            // XDomainRequest for IE.
            xhr = new XDomainRequest();
            xhr.open(t, n);
          }

          return xhr;
        },
        send: function () {
          xhr.setRequestHeader('Accept', 'application/json');

          xhr.onload = function () {
            p.call(a, 4, [JSON.parse(xhr.responseText)]);
          };

          xhr.onerror = function () {
            if (a.onerror && typeof a.onerror == 'function') {
              a.onerror();
            }
          };

          xhr.send();
        },
        setRequestHeader: function () {},
        getResponseHeader: function () {
          return '';
        },
        getAllResponseHeaders: function () {
          return [];
        },
      }),
      l(),
      a
    );
  };
})(typeof window !== 'undefined' && window),
  this.JSON || (this.JSON = {}),
  (function () {
    function l(e) {
      return e < 10 ? '0' + e : e;
    }

    function q(e) {
      return (
        (p.lastIndex = 0),
        p.test(e)
          ? '"' +
            e.replace(p, function (e) {
              var t = r[e];
              return typeof t == 'string'
                ? t
                : '\\u' + ('0000' + e.charCodeAt(0).toString(16)).slice(-4);
            }) +
            '"'
          : '"' + e + '"'
      );
    }

    function n(e, t) {
      var r,
        i,
        s,
        o,
        u = h,
        a,
        f = t[e];
      f && typeof f == 'object' && typeof f.toJSON == 'function' && (f = f.toJSON(e)),
        typeof j == 'function' && (f = j.call(t, e, f));
      switch (typeof f) {
        case 'string':
          return q(f);
        case 'number':
          return isFinite(f) ? String(f) : 'null';
        case 'boolean':
        case 'null':
          return String(f);
        case 'object':
          if (!f) return 'null';
          (h += m), (a = []);
          if (Object.prototype.toString.apply(f) === '[object Array]') {
            o = f.length;
            for (r = 0; r < o; r += 1) a[r] = n(r, f) || 'null';
            return (
              (s =
                a.length === 0
                  ? '[]'
                  : h
                  ? '[\n' + h + a.join(',\n' + h) + '\n' + u + ']'
                  : '[' + a.join(',') + ']'),
              (h = u),
              s
            );
          }
          if (j && typeof j == 'object') {
            o = j.length;
            for (r = 0; r < o; r += 1)
              (i = j[r]),
                typeof i == 'string' && ((s = n(i, f)), s && a.push(q(i) + (h ? ': ' : ':') + s));
          } else
            for (i in f)
              Object.hasOwnProperty.call(f, i) &&
                ((s = n(i, f)), s && a.push(q(i) + (h ? ': ' : ':') + s));
          return (
            (s =
              a.length === 0
                ? '{}'
                : h
                ? '{\n' + h + a.join(',\n' + h) + '\n' + u + '}'
                : '{' + a.join(',') + '}'),
            (h = u),
            s
          );
      }
    }
    typeof Date.prototype.toJSON != 'function' &&
      ((Date.prototype.toJSON = function (e) {
        return isFinite(this.valueOf())
          ? this.getUTCFullYear() +
              '-' +
              l(this.getUTCMonth() + 1) +
              '-' +
              l(this.getUTCDate()) +
              'T' +
              l(this.getUTCHours()) +
              ':' +
              l(this.getUTCMinutes()) +
              ':' +
              l(this.getUTCSeconds()) +
              'Z'
          : null;
      }),
      (String.prototype.toJSON =
        Number.prototype.toJSON =
        Boolean.prototype.toJSON =
          function (e) {
            return this.valueOf();
          }));
    var o =
        /[\u0000\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
      p =
        /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
      h,
      m,
      r = {
        '\b': '\\b',
        '	': '\\t',
        '\n': '\\n',
        '\f': '\\f',
        '\r': '\\r',
        '"': '\\"',
        '\\': '\\\\',
      },
      j;
    typeof JSON.stringify != 'function' &&
      (JSON.stringify = function (e, t, r) {
        var i;
        (h = ''), (m = '');
        if (typeof r == 'number') for (i = 0; i < r; i += 1) m += ' ';
        else typeof r == 'string' && (m = r);
        j = t;
        if (!t || typeof t == 'function' || (typeof t == 'object' && typeof t.length == 'number'))
          return n('', {
            '': e,
          });
        throw new Error('JSON.stringify');
      }),
      typeof JSON.parse != 'function' &&
        (JSON.parse = function (i, k) {
          function b(e, t) {
            var n,
              r,
              i = e[t];
            if (i && typeof i == 'object')
              for (n in i)
                Object.hasOwnProperty.call(i, n) &&
                  ((r = b(i, n)), r !== undefined ? (i[n] = r) : delete i[n]);
            return k.call(e, t, i);
          }
          var g;
          (o.lastIndex = 0),
            o.test(i) &&
              (i = i.replace(o, function (e) {
                return '\\u' + ('0000' + e.charCodeAt(0).toString(16)).slice(-4);
              }));
          if (
            /^[\],:{}\s]*$/.test(
              i
                .replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@')
                .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
                .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
            )
          )
            return (
              (g = eval('(' + i + ')')),
              typeof k == 'function'
                ? b(
                    {
                      '': g,
                    },
                    ''
                  )
                : g
            );
          throw new SyntaxError('JSON.parse');
        });
  })(),
  function () {
    if (typeof window !== 'undefined') {
      window.NZPost || (window.NZPost = {}),
        NZPost.Helpers || (NZPost.Helpers = {}),
        (NZPost.Helpers.addDomEvent = function (e, t, n) {
          return e.attachEvent != null
            ? ((e['e' + t + n] = n),
              (e['' + t + n] = function () {
                return e['e' + t + n](window.event);
              }),
              e.attachEvent('on' + t, e['' + t + n]))
            : e.addEventListener(t, n, !1);
        }),
        (NZPost.Helpers.getCoords = function (e) {
          var t;
          t = {
            top: e.offsetTop,
            left: e.offsetLeft,
          };
          while ((e = e.offsetParent)) (t.top += e.offsetTop), (t.left += e.offsetLeft);
          return t;
        }),
        (NZPost.Helpers.indexOf = function (e, t) {
          if (e == null) return -1;
          var n, r;
          if (e.indexOf) return e.indexOf(t);
          for (n = 0, r = e.length; n < r; n++) if (e[n] === t) return n;
          return -1;
        }),
        (NZPost.Helpers.extend = function (e, t) {
          var n, r;
          for (n in t) (r = t[n]), (e[n] = r);
          return e;
        }),
        (NZPost.Helpers.applyStyle = function (e, t) {
          var n, r, i;
          i = [];
          for (n in t) (r = t[n]), i.push((e.style[n] = r));
          return i;
        });
    }
  }.call(this),
  function () {
    if (typeof window !== 'undefined') {
      window.NZPost || (window.NZPost = {}),
        NZPost.Addressing || (NZPost.Addressing = {}),
        (NZPost.Addressing.Result = (function () {
          function e() {}
          return (
            (e.prototype.render = function () {
              var e = this;
              return (
                (this.el = document.createElement('li')),
                NZPost.Helpers.applyStyle(this.el, {
                  listStyle: 'none none',
                  margin: '0',
                  padding: '0',
                  background: 'none',
                }),
                (this.link = document.createElement('a')),
                this.identifier != null &&
                  this.link.setAttribute('data-indentifier', this.identifier),
                (this.link.href = '#'),
                NZPost.Helpers.addDomEvent(this.link, 'click', function (t) {
                  return (
                    e.parent.selectHighlighted(),
                    t.preventDefault ? t.preventDefault() : (t.returnValue = !1)
                  );
                }),
                NZPost.Helpers.addDomEvent(this.link, 'mouseover', function (t) {
                  var n;
                  return (
                    (n = e.parent.highlighted) != null && n.unhighlight(),
                    (e.parent.highlighted = e),
                    e.highlight()
                  );
                }),
                NZPost.Helpers.addDomEvent(this.link, 'mouseout', function (t) {
                  return e.unhighlight(), (e.parent.highlighted = null);
                }),
                NZPost.Helpers.addDomEvent(this.link, 'mousedown', function (t) {
                  return (e.parent.mousedownOnSelect = !0);
                }),
                NZPost.Helpers.addDomEvent(this.link, 'mouseup', function (t) {
                  return (e.parent.mousedownOnSelect = !1);
                }),
                (this.link.innerHTML = this.htmlContent),
                this.el.appendChild(this.link),
                this.parent.options.theme.itemClass != null
                  ? ((this.link.className =
                      '' + this.link.className + ' ' + this.parent.options.theme.itemClass),
                    (this.link.style.textDecoration = 'none'))
                  : ((this.link.style.display = 'block'),
                    (this.link.style.padding = '5px'),
                    (this.link.style.borderStyle = 'none'),
                    (this.link.style.textDecoration = 'none'),
                    (this.link.style.color = this.parent.options.theme.textColor),
                    (this.el.style.background = 'transparent')),
                this.el
              );
            }),
            (e.prototype.highlight = function () {
              return this.parent.options.theme.hoverClass != null
                ? (this.link.className =
                    '' + this.link.className + ' ' + this.parent.options.theme.hoverClass)
                : ((this.link.style.backgroundColor = this.parent.options.theme.hoverBackground),
                  (this.link.style.color = this.parent.options.theme.hoverTextColor));
            }),
            (e.prototype.unhighlight = function () {
              return this.parent.options.theme.hoverClass != null
                ? (this.link.className = this.link.className.replace(
                    new RegExp(this.parent.options.theme.hoverClass, 'gi'),
                    ''
                  ))
                : ((this.link.style.background = 'none'),
                  (this.link.style.color = this.parent.options.theme.textColor));
            }),
            (e.prototype.select = function () {
              return this.parent.populateResult(this);
            }),
            e
          );
        })());
    }
  }.call(this),
  function () {
    if (typeof window !== 'undefined') {
      var e = function (e, t) {
          return function () {
            return e.apply(t, arguments);
          };
        },
        t =
          [].indexOf ||
          function (e) {
            for (var t = 0, n = this.length; t < n; t++) if (t in this && this[t] === e) return t;
            return -1;
          },
        n = {}.hasOwnProperty,
        r = function (e, t) {
          function i() {
            this.constructor = e;
          }
          for (var r in t) n.call(t, r) && (e[r] = t[r]);
          return (
            (i.prototype = t.prototype), (e.prototype = new i()), (e.__super__ = t.prototype), e
          );
        };
      window.NZPost || (window.NZPost = {}),
        NZPost.Addressing || (NZPost.Addressing = {}),
        (NZPost.Addressing.Checker = (function () {
          function n(t, n, r) {
            var i, s, o, u;
            this.autocomplete_element = t;
            this.public_api_key = n;
            this.options = r != null ? r : {};
            this.findAddresses = e(this.findAddresses, this);
            this.defaultOptions = {
              max_results: 10,
              search_type: 'All',
              min_match_score: 50,
              dym_activation_count: 5,
              did_you_mean: !1,
              min_default_ul_width: 226,
              not_found_head: 'Address not found. Please try again.',
              not_found_body: 'Or enter my address manually',
              include_partials: !1,
            };
            this.defaultThemes = {
              boxBorder: '1px solid #2c2c2c',
              boxColor: '#fff',
              textColor: '#444',
              hoverBackground: '#696969',
              hoverTextColor: '#ffffff',
              'z-index': '1000',
            };
            this.options = NZPost.Helpers.extend(this.defaultOptions, this.options);
            this.options.theme = NZPost.Helpers.extend(this.defaultThemes, this.options.theme);
            this.autocomplete_element.setAttribute('autocomplete', 'off');
            this.autocomplete_element && this.addAutocompleteListeners();
            this.output_box = document.createElement('ul');
            this.output_box.id = 'address_suggestion_box';
            this.applyStyleToAutoCompleteList();
            document.body.appendChild(this.output_box);

            if (this.options.third_parties != null) {
              u = this.options.third_parties;
              for (s = 0, o = u.length; s < o; s++) (i = u[s]), (i.checker = this);
            }
            this.autocomplete_element.getAttribute('autofocus') && (this.hasFocus = !0),
              (this.requests_pending = 0);
          }
          return (
            (n.prototype.enable = function () {
              return (this.disabled = !1);
            }),
            (n.prototype.disable = function () {
              return (this.disabled = !0);
            }),
            (n.prototype.applyStyleToAutoCompleteList = function () {
              return (
                this.applyDimensionsToAutoCompleteList(),
                this.options.theme.boxClass != null
                  ? (this.output_box.className += ' ' + this.options.theme.boxClass)
                  : NZPost.Helpers.applyStyle(this.output_box, {
                      border: this.options.theme.boxBorder,
                      backgroundColor: this.options.theme.boxColor,
                      listStyle: 'none',
                      margin: '0',
                      padding: '0',
                    })
              );
            }),
            (n.prototype.applyDimensionsToAutoCompleteList = function () {
              var e, t;
              return (
                (e = NZPost.Helpers.getCoords(this.autocomplete_element)),
                NZPost.Helpers.applyStyle(this.output_box, {
                  display: 'none',
                  position: 'absolute',
                  top: '' + (this.autocomplete_element.offsetHeight + e.top) + 'px',
                  left: '' + e.left + 'px',
                  overflow: 'hidden',
                  listStylePosition: 'outside',
                }),
                this.options.theme.boxWidth != null
                  ? (this.output_box.style.width = this.options.theme.boxWidth)
                  : ((t = Math.max.apply(this, [
                      this.autocomplete_element.offsetWidth,
                      this.options.min_default_ul_width,
                    ])),
                    (this.output_box.style.width = '' + t + 'px')),
                this.options.theme['z-index'] != null
                  ? (this.output_box.style.zIndex = this.options.theme['z-index'])
                  : (this.output_box.style.zIndex = 'auto')
              );
            }),
            (n.prototype.addFooter = function () {
              var e, t;
              return (
                (t = document.createElement('li')),
                NZPost.Helpers.applyStyle(t, {
                  display: 'block',
                  opacity: 1,
                  background: '#fff',
                  padding: '5px',
                }),
                (e = document.createElement('div')),
                NZPost.Helpers.applyStyle(e, {
                  background:
                    'url(' +
                    document.location.protocol +
                    '//address.nzpost.co.nz/assets/brought-to-you-by-nzpost.png) right center no-repeat',
                  whiteSpace: 'nowrap',
                  display: 'block',
                  padding: '0',
                  borderStyle: 'none',
                  height: '24px',
                }),
                t.appendChild(e),
                this.output_box.appendChild(t)
              );
            }),
            (n.prototype.addNotFoundMessage = function () {
              var e, t, n;
              var parent = this;
              return (
                (n = document.createElement('li')),
                NZPost.Helpers.applyStyle(n, {
                  display: 'block',
                  opacity: 1,
                  padding: '5px 5px 5px 5px',
                  color: '#444444',
                }),
                (t = document.createElement('p')),
                (t.innerHTML = this.options.not_found_head),
                (t.className = 'address-auto-errorhead'),
                NZPost.Helpers.applyStyle(t, {
                  marginBottom: '5px',
                  fontWeight: 'bold',
                }),
                (e = document.createElement('a')),
                (e.innerHTML = this.options.not_found_body),
                (e.className = 'address-auto-errorbody'),
                NZPost.Helpers.addDomEvent(e, 'mousedown', function (t) {
                  return (parent.mousedownOnSelect = !0);
                }),
                NZPost.Helpers.addDomEvent(e, 'mouseup', function (t) {
                  return (parent.mousedownOnSelect = !1);
                }),
                NZPost.Helpers.addDomEvent(e, 'click', function () {
                  if (
                    parent.options.onlinkclick &&
                    typeof parent.options.onlinkclick == 'function'
                  ) {
                    parent.options.onlinkclick(parent.autocomplete_element.value);
                  }
                  parent.removeSuggestions();
                }),
                NZPost.Helpers.applyStyle(e, {
                  marginBottom: '0',
                }),
                n.appendChild(t),
                n.appendChild(e),
                this.output_box.appendChild(n)
              );
            }),
            (n.prototype.addAutocompleteListeners = function () {
              var e = this;
              return (
                NZPost.Helpers.addDomEvent(this.autocomplete_element, 'focus', function (t) {
                  return (e.hasFocus = !0);
                }),
                NZPost.Helpers.addDomEvent(this.autocomplete_element, 'keypress', function (t) {
                  var n, r;
                  n = t.which || t.keyCode;
                  if (n === 13) {
                    (e.hide_callbacks = !0), (r = e.highlighted != null), e.selectHighlighted();
                    if (r) return t.preventDefault ? t.preventDefault() : (t.returnValue = !1), !1;
                  }
                }),
                NZPost.Helpers.addDomEvent(this.autocomplete_element, 'keyup', function () {
                  if (e.options.ontype && typeof e.options.ontype == 'function') {
                    e.options.ontype(e.autocomplete_element.value);
                  }
                }),
                NZPost.Helpers.addDomEvent(this.autocomplete_element, 'keydown', function (t) {
                  var n;
                  n = t.which || t.keyCode;
                  switch (n) {
                    case 38:
                      return e.moveHighlight(-1);
                    case 40:
                      return e.moveHighlight(1);
                    case 9:
                      if (e.highlighted != null) return e.selectHighlighted();
                      break;
                    case 27:
                      return e.removeSuggestions();
                    case 37:
                    case 39:
                    case 13:
                    case 16:
                    case 17:
                    case 18:
                    case 20:
                    case 33:
                    case 34:
                    case 35:
                    case 36:
                    case 91:
                    case 92:
                    case 93:
                      break;
                    default:
                      return (
                        (e.hide_callbacks = !1),
                        e.timeout != null && clearTimeout(e.timeout),
                        (e.timeout = setTimeout(function () {
                          return e.getAutocomplete();
                        }, 400))
                      );
                  }
                }),
                NZPost.Helpers.addDomEvent(this.autocomplete_element, 'blur', function (t) {
                  if (e.mousedownOnSelect !== !0)
                    return (e.hasFocus = !1), e.removeSuggestions(), e.onnotfocus();
                })
              );
            }),
            (n.prototype.moveHighlight = function (e) {
              var t;
              if (this.current_list != null)
                return (
                  (t =
                    this.highlighted != null && this.current_list != null
                      ? NZPost.Helpers.indexOf(this.current_list, this.highlighted)
                      : -1),
                  this.highlighted && this.highlighted.unhighlight(),
                  (t += e),
                  t < -1
                    ? (t = this.current_list.length - 1)
                    : t >= this.current_list.length && (t = -1),
                  (this.highlighted = this.current_list[t]),
                  this.highlighted != null
                    ? (this.highlighted.highlight(),
                      (this.autocomplete_element.value = this.highlighted.textContent))
                    : (this.autocomplete_element.value = this.val)
                );
            }),
            (n.prototype.selectHighlighted = function () {
              var e;
              return (
                this.highlighted != null &&
                  ((e = !0),
                  this.options.select &&
                    typeof this.options.select == 'function' &&
                    (e = this.options.select(this.highlighted.elements)),
                  e !== !1 && this.highlighted.fetch()),
                this.removeSuggestions()
              );
            }),
            (n.prototype.onnotfocus = function () {
              var e;
              return (
                (e = !0),
                this.options.onnotfocus &&
                  typeof this.options.onnotfocus == 'function' &&
                  this.options.onnotfocus(this.autocomplete_element.value)
              );
            }),
            (n.prototype.onnotfound = function () {
              var e;
              return (
                (e = !0),
                this.options.onnotfound &&
                  typeof this.options.onnotfound == 'function' &&
                  this.options.onnotfound()
              );
            }),
            (n.prototype.getAutocomplete = function () {
              this.options.format_query != null && typeof this.options.format_query == 'function'
                ? (this.val = this.options.format_query(this.autocomplete_element.value))
                : (this.val = this.autocomplete_element.value);

              var noValue = this.val === '' || this.disabled === !0;
              return noValue
                ? this.removeSuggestions()
                : ((this.suggested_addresses = []),
                  this.invalidate_pending_requests(),
                  this.options.third_parties != null && this.getThirdParties(this.val),
                  this.options.include_partials && this.getPartialAddresses(this.val),
                  this.getAddresses(this.val));
            }),
            (n.prototype.invalidate_pending_requests = function () {
              return (
                (this.last_suggest_address_xhr = null),
                (this.last_suggest_partial_address_xhr = null),
                (this.last_find_address_xhr = null),
                (this.requests_pending = 0)
              );
            }),
            (n.prototype.handleAjaxError = function (e) {
              return e.error_code === 1002 || e.error_code === 1005
                ? alert(
                    'The supplied Public API Key is not valid for this domain.\nPlease check your Public API Key or contact NZ Post for support.'
                  )
                : typeof console != 'undefined' && console !== null
                ? console.log(e)
                : void 0;
            }),
            (n.prototype.getAddresses = function (e) {
              var t,
                n,
                r,
                i = this;
              return (
                (this.requests_pending += 1),
                (n =
                  typeof this.options.search_type == 'function'
                    ? this.options.search_type()
                    : this.options.search_type),
                (t =
                  typeof this.options.max_results == 'function'
                    ? this.options.max_results()
                    : this.options.max_results),
                (r = new jXHR()),
                (r.onerror = function () {
                  i.onnotfound();
                  i.showServiceUnavailable();
                }),
                (r.onreadystatechange = function (n) {
                  var s, o, u, a;
                  if (r.readyState === 4 && i.last_suggest_address_xhr === r) {
                    i.requests_pending -= 1;
                    if (n.success) {
                      a = n.addresses;

                      if (a.length == 0) {
                        i.onnotfound();
                      }

                      for (o = 0, u = a.length; o < u; o++)
                        (s = a[o]),
                          i.suggested_addresses.push(
                            new NZPost.Addressing.Address(s, i, 'suggest_address')
                          );

                      return (
                        i.options.did_you_mean &&
                          n.addresses.length <= i.options.dym_activation_count &&
                          i.findAddresses(e, t - n.addresses.length),
                        i.showSuggestions()
                      );
                    }
                    return i.handleAjaxError(n);
                  }
                }),
                r.open(
                  'GET',
                  'https://api.nzpost.co.nz/addresschecker/1.0/suggest?q=' +
                    escape(e) +
                    '&access_token=' +
                    this.public_api_key +
                    '&type=' +
                    n +
                    '&max=' +
                    t
                ),
                (this.last_suggest_address_xhr = r),
                r.send()
              );
            }),
            (n.prototype.showServiceUnavailable = function () {
              this.invalidate_pending_requests();
              var originalError = this.options.not_found_head;
              this.options.not_found_head = 'Service unavailable. Please try again.';
              this.showSuggestions();
              this.options.not_found_head = originalError;
            }),
            (n.prototype.getPartialAddresses = function (e) {
              var t,
                n,
                r = this;
              return (
                (this.requests_pending += 1),
                (t =
                  typeof this.options.max_results == 'function'
                    ? this.options.max_results()
                    : this.options.max_results),
                (n = new jXHR()),
                (n.onreadystatechange = function (e) {
                  var t, i, s, o, u;
                  if (n.readyState === 4 && r.last_suggest_partial_address_xhr === n) {
                    r.requests_pending -= 1;
                    if (e.success) {
                      (t = []), (u = e.addresses);
                      for (s = 0, o = u.length; s < o; s++)
                        (i = u[s]),
                          t.push(
                            new NZPost.Addressing.PartialAddress(i, r, 'suggest_partial_address')
                          );
                      return (
                        (r.suggested_addresses = t.concat(r.suggested_addresses)),
                        r.showSuggestions()
                      );
                    }
                    return r.handleAjaxError(e);
                  }
                }),
                n.open(
                  'GET',
                  '//address.nzpost.co.nz/api/suggest_partial.json?q=' +
                    escape(e) +
                    '&callback=?&public_api_key=' +
                    this.public_api_key +
                    '&max=' +
                    t
                ),
                (this.last_suggest_partial_address_xhr = n),
                n.send()
              );
            }),
            (n.prototype.findAddresses = function (e, t) {
              var n,
                r,
                i,
                s,
                o,
                u = this;
              return (
                (this.requests_pending += 1),
                (s =
                  typeof this.options.search_type == 'function'
                    ? this.options.search_type()
                    : this.options.search_type),
                (i = e.split(',')),
                (n = (function () {
                  var e, t;
                  t = [];
                  for (r = e = 0; e <= 4; r = ++e)
                    i[r] != null
                      ? t.push('address_line_' + (r + 1) + '=' + escape(i[r]))
                      : t.push(void 0);
                  return t;
                })()),
                (o = new jXHR()),
                (o.onreadystatechange = function (e) {
                  var t, n, r, i;
                  if (o.readyState === 4 && u.last_find_address_xhr === o) {
                    u.requests_pending -= 1;
                    if (e.success) {
                      (u.found_addresses = []), (i = e.addresses);
                      for (n = 0, r = i.length; n < r; n++)
                        (t = i[n]),
                          t.MatchScore > u.options.min_match_score &&
                            u.found_addresses.push(
                              new NZPost.Addressing.Address(t, u, 'find_address')
                            );
                      return u.showSuggestions();
                    }
                    return u.handleAjaxError(e);
                  }
                }),
                o.open(
                  'GET',
                  '//address.nzpost.co.nz/api/find.json?&callback=?&public_api_key=' +
                    this.public_api_key +
                    '&type=' +
                    s +
                    '&max=' +
                    t +
                    '&' +
                    n.join('&')
                ),
                (this.last_find_address_xhr = o),
                o.send()
              );
            }),
            (n.prototype.getThirdParties = function (e) {
              var t, n, r, i, s;
              (i = this.options.third_parties), (s = []);
              for (n = 0, r = i.length; n < r; n++)
                (t = i[n]), (this.requests_pending += 1), s.push(t.search(e));
              return s;
            }),
            (n.prototype.showSuggestions = function () {
              var e, n, r, i, s, o, u, a, f, l, c, h, p, d, v;
              if (this.hasFocus && this.hide_callbacks === !1) {
                i = [];
                if (this.options.third_parties != null) {
                  h = this.options.third_parties;
                  for (o = 0, f = h.length; o < f; o++) (s = h[o]), (i = i.concat(s.results));
                }
                this.suggested_addresses != null && (i = i.concat(this.suggested_addresses)),
                  (n = (function () {
                    var t, n, r;
                    r = [];
                    for (t = 0, n = i.length; t < n; t++)
                      (e = i[t]), r.push('' + e.elements.DPID + ':' + e.elements.SourceDesc);
                    return r;
                  })());
                if (this.found_addresses != null) {
                  p = this.found_addresses;
                  for (u = 0, l = p.length; u < l; u++) {
                    e = p[u];
                    if (
                      ((d = '' + e.elements.DPID + ':' + e.elements.SourceDesc), t.call(n, d) < 0)
                    )
                      i = i.concat(e);
                  }
                }
                if (this.requests_pending === 0) {
                  this.applyDimensionsToAutoCompleteList(),
                    (this.output_box.style.display = 'block'),
                    (this.output_box.innerHTML = '');
                  if (i.length === 0) this.addNotFoundMessage();
                  else {
                    (this.current_list = i.slice(0, this.options.max_results)),
                      (v = this.current_list);
                    for (a = 0, c = v.length; a < c; a++)
                      (r = v[a]), this.output_box.appendChild(r.render());
                  }
                  return this.addFooter();
                }
              }
            }),
            (n.prototype.removeSuggestions = function () {
              var e, t, n, r;
              (this.output_box.style.display = 'none'),
                (this.current_list = null),
                (this.suggested_addresses = []);

              if (this.options.third_parties != null) {
                r = this.options.third_parties;
                for (t = 0, n = r.length; t < n; t++) (e = r[t]), (e.results = []);
              }
              return (this.highlighted = null);
            }),
            (n.prototype.populateResult = function (e) {
              this.options.populates != null
                ? this.populateFields(e)
                : (this.autocomplete_element.value = e.textContent);
              if (this.options.success && typeof this.options.success == 'function')
                return this.options.success(e.info, e.additional_info);
            }),
            (n.prototype.populateFields = function (e) {
              var t, n, r, i;
              i = this.options.populates;
              for (t in i)
                (r = i[t]),
                  (n = document.getElementById(t)),
                  n != null &&
                    (typeof r == 'function'
                      ? (n.value = r(e.info))
                      : typeof r == 'string' && (n.value = e.info[r] || ''));
            }),
            (n.prototype.setOption = function (e, t) {
              var n, r, i;
              if (e != null && typeof e == 'string' && t != null) return (this.options[e] = t);
              if (e != null && typeof e == 'object') {
                i = [];
                for (n in e) (r = e[n]), i.push((this.options[n] = r));
                return i;
              }
            }),
            n
          );
        })()),
        (NZPost.Addressing.Validator = NZPost.Addressing.Checker),
        (NZPost.Addressing.Address = (function (e) {
          function t(e, t, n) {
            (this.elements = e), (this.parent = t), (this.source = n);
          }
          return (
            r(t, e),
            (t.prototype.render = function () {
              return (
                (this.textContent = this.elements.FullAddress),
                (this.htmlContent =
                  this.parent.options.format_item != null
                    ? this.parent.options.format_item(this.elements)
                    : this.textContent),
                (this.identifier = this.elements.DPID),
                t.__super__.render.call(this)
              );
            }),
            (t.prototype.fetch = function () {
              var e,
                t,
                n = this;
              return (
                (e = this.elements.DPID),
                (t = new jXHR()),
                (t.onerror = function () {
                  n.onnotfound();
                  n.showServiceUnavailable();
                }),
                (t.onreadystatechange = function (e) {
                  var r, i, s, o, u, a, f, l;
                  if (t.readyState === 4) {
                    if (e.success) {
                      f = e.details;
                      for (s = 0, u = f.length; s < u; s++) {
                        (r = f[s]),
                          r.SourceDesc === n.elements.SourceDesc && (n.info = r),
                          (r.FullAddress = r.AddressLine1),
                          (l = [r.AddressLine2, r.AddressLine3, r.AddressLine4, r.AddressLine5]);
                        for (o = 0, a = l.length; o < a; o++)
                          (i = l[o]), i != null && (r.FullAddress = r.FullAddress.concat(', ' + i));
                      }
                      return (
                        (n.additional_info = {
                          addresses: e.details,
                        }),
                        n.select()
                      );
                    }
                    return n.parent.handleAjaxError(e);
                  }
                }),
                t.open(
                  'GET',
                  'https://api.nzpost.co.nz/addresschecker/1.0/details?dpid=' +
                    e +
                    '&type=All&access_token=' +
                    this.parent.public_api_key
                ),
                t.send()
              );
            }),
            t
          );
        })(NZPost.Addressing.Result)),
        (NZPost.Addressing.PartialAddress = (function (e) {
          function t(e, t, n) {
            (this.elements = e), (this.parent = t), (this.source = n);
          }
          return (
            r(t, e),
            (t.prototype.render = function () {
              return (
                (this.textContent = this.elements.FullPartial),
                (this.htmlContent =
                  this.parent.options.format_item != null
                    ? this.parent.options.format_item(this.elements)
                    : this.textContent),
                (this.identifier = this.elements.UniqueId),
                t.__super__.render.call(this)
              );
            }),
            (t.prototype.fetch = function () {
              var e,
                t,
                n = this;
              return (
                (e = this.elements.UniqueId),
                (t = new jXHR()),
                (t.onreadystatechange = function (e) {
                  var r, i, s, o;
                  if (t.readyState === 4) {
                    if (e.success) {
                      o = e.details;
                      for (i = 0, s = o.length; i < s; i++)
                        (r = o[i]), r.SourceDesc === n.elements.SourceDesc && (n.info = r);
                      return (
                        (n.additional_info = {
                          addresses: e.details,
                        }),
                        n.select()
                      );
                    }
                    return n.parent.handleAjaxError(e);
                  }
                }),
                t.open(
                  'GET',
                  '//address.nzpost.co.nz/api/partial_details.json?unique_id=' +
                    e +
                    '&callback=?&public_api_key=' +
                    this.parent.public_api_key
                ),
                t.send()
              );
            }),
            t
          );
        })(NZPost.Addressing.Result));
    }
  }.call(this),
  function () {
    if (typeof window !== 'undefined') {
      var e = function (e, t) {
          return function () {
            return e.apply(t, arguments);
          };
        },
        t = {}.hasOwnProperty,
        n = function (e, n) {
          function i() {
            this.constructor = e;
          }
          for (var r in n) t.call(n, r) && (e[r] = n[r]);
          return (
            (i.prototype = n.prototype), (e.prototype = new i()), (e.__super__ = n.prototype), e
          );
        };
      window.NZPost || (window.NZPost = {}),
        NZPost.Addressing || (NZPost.Addressing = {}),
        (NZPost.Addressing.ThirdPartyService = (function () {
          function t(t, n) {
            var r = this;
            (this.search_fn = t),
              (this.options = n != null ? n : {}),
              (this._response = e(this._response, this)),
              (this.results = []),
              (this.response = function (e, t) {
                return r._response.apply(r, arguments);
              });
          }
          return (
            (t.prototype.search = function (e) {
              return (this.last_query = e), this.search_fn(e, this.response);
            }),
            (t.prototype._response = function (e, t) {
              var n, r, i;
              this.results = [];
              if (this.last_query === e) {
                for (r = 0, i = t.length; r < i; r++)
                  (n = t[r]), this.results.push(new NZPost.Addressing.ThirdPartyResult(n, this));
                this.checker.requests_pending -= 1;
                if (this.checker != null) return this.checker.showSuggestions();
              }
            }),
            t
          );
        })()),
        (NZPost.Addressing.ThirdPartyResult = (function (e) {
          function t(e, t, n) {
            (this.elements = e),
              (this.service = t),
              (this.source = n != null ? n : 'third_party'),
              (this.parent = this.service.checker);
          }
          return (
            n(t, e),
            (t.prototype.render = function () {
              return (
                (this.textContent = this.elements.value),
                (this.htmlContent =
                  this.service.options.format_item != null
                    ? this.service.options.format_item(this.elements)
                    : this.textContent),
                t.__super__.render.call(this)
              );
            }),
            (t.prototype.fetch = function () {
              return (this.info = this.elements.data), this.select();
            }),
            t
          );
        })(NZPost.Addressing.Result));
    }
  }.call(this);
