import React from 'react';

import Container from '@nib-components/container';
import Heading from '@nib-components/heading';
import { Stack, Columns, Column } from '@nib/layout';

import { BaseFormikProps, sharedColumnSpace } from '../../pages/ngatiwhatuaorakei/enrol';

// eslint-disable-next-line import/no-named-as-default
import TextboxFormControl from '../FormControl/TextboxFormControl';
import SelectFormControl, { SelectShape } from './controls/SelectFormControl';
import DateTextboxFormControl from './controls/DateTextboxFormControl';
import RadioButtonFormControl from './controls/RadioButtonFormControl';
import {
  isEmpty,
  validateNames,
  isInvalidDate,
  isInvalidGender,
  validationRegex,
} from '../../utils/form-validation';

const getTitleOptions = (): SelectShape => {
  return {
    dr: 'Dr',
    mr: 'Mr',
    mrs: 'Mrs',
    ms: 'Ms',
    miss: 'Miss',
    master: 'Master',
  };
};

interface PersonalDetailsProps extends BaseFormikProps {}

const PersonalDetailsFormSection = (props: PersonalDetailsProps): JSX.Element => {
  const { touched, values, errors } = props;
  const { title, firstName, middleName, surname, dateOfBirth, gender, preferredGender } = values;

  return (
    <Container>
      <Stack space={4}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
          Personal details
        </Heading>
        <Columns space={sharedColumnSpace} collapseBelow="md">
          <Column width={{ md: '1/3', lg: '1/5' }}>
            <SelectFormControl
              id="title"
              value={title}
              isTouched={touched.title}
              fieldName="title"
              label="Title"
              options={getTitleOptions()}
              width="75%"
              placeholder="Select a title"
              error={errors.title}
              valid={!errors.title}
              {...props}
            />
          </Column>
          <Column width={{ md: '1/3', lg: '2/5' }}>
            <TextboxFormControl
              id="first-name"
              value={firstName}
              isTouched={touched.firstName}
              fieldName="firstName"
              autoComplete="given-name"
              label="First name"
              error={errors.firstName}
              valid={!errors.firstName}
              {...props}
            />
          </Column>
          <Column width={{ md: '1/3', lg: '2/5' }}>
            <TextboxFormControl
              id="middle-name"
              value={middleName}
              isTouched={touched.middleName}
              fieldName="middleName"
              autoComplete="additional-name"
              label="Middle name"
              error={errors.middleName}
              valid={!errors.middleName}
              isEmptyAndOptional={true}
              {...props}
            />
          </Column>
        </Columns>
        <Columns space={sharedColumnSpace} collapseBelow="md">
          <Column width={{ md: '2/3', lg: '2/5' }}>
            <TextboxFormControl
              id="surname"
              value={surname}
              isTouched={touched.surname}
              fieldName="surname"
              autoComplete="family-name"
              label="Surname"
              error={errors.surname}
              valid={!errors.surname}
              {...props}
            />
          </Column>
          <Column width={{ md: '1/3', lg: '1/5' }}>
            <DateTextboxFormControl
              id="date-of-birth"
              value={dateOfBirth}
              isTouched={touched.dateOfBirth}
              fieldName="dateOfBirth"
              label="Date of Birth"
              error={errors.dateOfBirth}
              valid={!errors.dateOfBirth}
              isEmptyAndOptional={true}
              {...props}
            />
          </Column>
        </Columns>
        <Columns space={sharedColumnSpace} collapseBelow="md">
          <Column width="content">
            <RadioButtonFormControl
              id="gender"
              value={gender}
              isTouched={touched.gender}
              fieldName="gender"
              label="Biological gender"
              error={errors.gender}
              valid={!errors.gender}
              showButtonStyle={true}
              options={{ male: 'Male', female: 'Female' }}
              {...props}
            />
          </Column>
          <Column>
            <TextboxFormControl
              id="preferred-gender"
              value={preferredGender}
              isTouched={touched.preferredGender}
              fieldName="preferredGender"
              autoComplete="sex"
              label="Preferred gender (if different to biological gender)"
              error={errors.preferredGender}
              valid={!errors.preferredGender}
              isEmptyAndOptional={true}
              {...props}
            />
          </Column>
        </Columns>
      </Stack>
    </Container>
  );
};

export const validatePersonalDetails = (errors, values) => {
  if (isEmpty(values.title)) {
    errors.title = 'Please select a title';
  }

  validateNames(errors, values, 'firstName', 'first ');
  validateNames(errors, values, 'surname', 'sur');

  let middleName = values.middleName || '';
  middleName = middleName.trim();
  if (middleName.length > 30) {
    errors.middleName =
      "Looks like your middle name is a bit too long, we'll need you to give us a" +
      ' call to sort it out';
  }

  if (!isEmpty(middleName) && !validationRegex.name.test(middleName)) {
    errors.middleName =
      "Sorry, we don't accept numbers or other special characters in middle names";
  }

  if (isInvalidDate(values.dateOfBirth)) {
    errors.dateOfBirth = 'Please enter date of birth';
  }

  if (isInvalidGender(values.gender)) {
    errors.gender = 'Please select a gender';
  }

  if (!isEmpty(values.preferredGender) && !validationRegex.name.test(values.preferredGender)) {
    errors.preferredGender =
      "Sorry, we don't accept numbers or other special characters in preferred gender";
  }
};

export default PersonalDetailsFormSection;
